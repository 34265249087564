import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { AppButton, DotsBackground, EzDivisor } from "../common";
import { Colors, Devices } from "../variables";

export const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.875rem;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    width: 50%;
    margin-bottom: 4.375rem;
  }

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    width: 33%;
    margin-bottom: 6.563rem;
  }
`;

export const ImageWrapper = styled("div")`
  align-self: center;
  height: 250px;
  width: 250px;
  min-height: 250px;
  min-width: 250px;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    // height: 0;
    // padding-top: 100%;
    // width: 100%;
    position: relative;
  }
`;

export const Image = styled(GatsbyImage)`
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;

  img {
    border-radius: 50%;
  }

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    position: initial !important;
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
  }
`;

export const Content = styled("div")`
  flex: 0 0 50%;
`;

export const Title = styled("h2")`
  padding: 0;
  text-align: center;
  margin: 15px 0 0;
  color: ${Colors.ezBlack};
  font-size: 1.5rem;
  line-height: 4rem;
  font-weight: 600;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    font-size: 1.75rem;
  }
`;

export const Divisor = styled(EzDivisor)`
  &::after {
    background-color: ${Colors.sharpBlue};
  }
`;

export const Subtitle = styled("h3")`
  text-align: center;
  margin-top: 2px;
  font-size: 14px;
  padding: 0;
  margin-bottom: 13px;
  color: ${Colors.ezBlack};
  font-weight: 600;
  opacity: 0.5;

  /* >= 1200px; */
  /* @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    font-size: 0.875rem;
    padding: 0 0 2rem 0;
  } */

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    font-size: 18px;
    margin-top: -3px;
    padding-bottom: 7px;
  }
`;

export const Description = styled("div")`
  text-align: center;
  padding: 0;
  color: ${Colors.ezGray};
  font-size: 0.9375rem;
  line-height: 1.75rem;
  font-weight: 400;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    text-align: left;
  }
`;

export const Footer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    justify-content: left;
  }
`;

export const SocialLink = styled("a")`
  font-size: 0.9375rem;
  border-bottom: 1px solid transparent;
  color: ${Colors.sharpBlue};

  &:focus {
    outline: none;
    border-bottom: 1px solid ${Colors.sharpBlue};
    color: ${Colors.sharpBlue};
  }
  &:hover {
    color: ${Colors.sharpBlue};
    text-decoration: none;
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    font-weight: 600;
    color: ${Colors.ezBlack};
  }
`;
