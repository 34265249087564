import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

import * as StylingTeamMemberSection from "../../styles/AboutPage/StylingTeamMemberSection";
import { TeamMember } from "../../templates/about";
import { useWindowSize } from "../CustomHooks/useWindowSize";

interface TeamMemberSectionProps {
  section: TeamMember;
  reversed?: boolean;
}

const TeamMemberSection: React.FC<TeamMemberSectionProps> = ({
  section,
  reversed,
}) => {
  const { width } = useWindowSize();
  return (
    <StylingTeamMemberSection.Container>
      <StylingTeamMemberSection.ImageWrapper>
        <StylingTeamMemberSection.Image
          image={getImage(section.avatar.localFile) as IGatsbyImageData}
          alt={section.avatar.alt_text}
        />
      </StylingTeamMemberSection.ImageWrapper>

      <StylingTeamMemberSection.Content>
        <StylingTeamMemberSection.Title>
          {section.full_name}
        </StylingTeamMemberSection.Title>

        <StylingTeamMemberSection.Subtitle>
          {section.position}
        </StylingTeamMemberSection.Subtitle>

        <StylingTeamMemberSection.Description dangerouslySetInnerHTML={{__html: section.description}}/>

        {/* {section.linkedin?.title !== "" && (
          <StylingTeamMemberSection.Footer>
            <StylingTeamMemberSection.SocialLink
              href={section.linkedin.url}
              target="_blank"
            >
              {section.linkedin.title}
            </StylingTeamMemberSection.SocialLink>
          </StylingTeamMemberSection.Footer>
        )} */}
      </StylingTeamMemberSection.Content>
    </StylingTeamMemberSection.Container>
  );
};

export default TeamMemberSection;
