import React, { useState } from "react";
import {
  MenuItems,
  ServicesWeOfferType,
  TestimonialsType,
} from "../shared/interfaces/graphql.interface";
import { EzDivisor, RespContainer } from "../styles/common";
import styled from "styled-components";
import { Colors, Devices } from "../styles/variables";
// import BannerRedesignVast from "../components/BannerRedesignVast";
import BannerRedesign from "../components/BannerRedesign";
import TeamMemberSection from "../components/AboutPageComponents/TeamMemberSection";
import ServicesWeOffer from "../components/ReUsables/ServicesWeOffer";
import Testimonials from "../components/ReUsables/Testimonials";

interface AboutDataProps {
  pageContext: { data: AboutContext };
}

export type TeamMember = {
  full_name: string;
  position: string;
  description: string;
  linkedin: {
    title: string;
    url: string;
  };
  avatar: {
    localFile: any;
    alt_text: string;
  };
};

interface AboutContext {
  testimonials: TestimonialsType;
  footerMenuItems: MenuItems;
  headerMenuItems: MenuItems;
  title: string;
  content: string;
  acf: {
    banner_title: string;
    banner_subtitle: string;
    banner_description: string;
    banner_image: {
      localFile: any;
      alt_text: string;
    };
    vvm_info: {
      values: string;
      vision: string;
      mission: string;
    };
    team: {
      title: string;
      members: Array<TeamMember>;
    };
  };
  services_we_offer: ServicesWeOfferType;
}

const contact: React.FC<AboutDataProps> = ({ pageContext }) => {
  let pageContextData: AboutContext = pageContext.data;

  const vvm_titles = ["Our Values", "Our Vision", "Our Mission"];

  return (
    <>
      <BannerRedesign
        title={pageContextData.acf.banner_title}
        title_2={pageContextData.acf.banner_title_2}
        subtitle={pageContextData.acf.banner_description}
        // description={pageContextData.acf.banner_description}
        banner={pageContextData.acf.banner_image}
      />

      <GrayBG>
        <VVMContainer>
          <VVMWrapper>
            {Object.values(pageContextData.acf.vvm_info).map((item, idx) => (
              <ItemWrapper key={`vvmi${idx}`}>
                <ItemTitleWrapper>
                  <ItemTitle>{vvm_titles[idx]}</ItemTitle>
                  <EzDivisor alwaysBlue />
                </ItemTitleWrapper>
                <ItemText>{item}</ItemText>
              </ItemWrapper>
            ))}
          </VVMWrapper>
        </VVMContainer>
      </GrayBG>

      <RespContainer>
        <TeamTitleWrapper>
          <TeamTitle>{pageContextData.acf.team.title}</TeamTitle>
          <AboutEzDivisor />
        </TeamTitleWrapper>

        <TeamMembersList>
          {pageContextData.acf.team.members.map((member, id) => (
            <TeamMemberSection key={`memb${id}`} section={member} />
          ))}
        </TeamMembersList>
      </RespContainer>

      <GrayBgWrapper>
        <Testimonials testimonials={pageContextData.testimonials} />
      </GrayBgWrapper>

      <GrayBgWrapper>
        <ServicesWeOffer
          services_we_offer={pageContextData.services_we_offer}
          narrowBottomMargin
        />
      </GrayBgWrapper>
    </>
    // </AboutRespContainer>
  );
};

export default contact;

const AboutRespContainer = styled(RespContainer)`
  /* margin-bottom: 6.25rem; */

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    /* margin-bottom: 3.75rem; */
  }
`;

const Title = styled("h1")`
  color: ${Colors.ezBlack};
  font-weight: 600;
  font-size: 2rem;
  margin: 3rem;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    font-size: 6.25rem;
    margin: 7.75rem 0;
  }
`;

const TeamTitleWrapper = styled("div")`
  /* margin-top: 3.375rem; */
  width: 100%;
  margin-top: 53px;
  margin-bottom: 28px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-top: 4rem;
    margin-bottom: 4.5rem;
  }
`;

const TeamMembersList = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    flex-direction: row;

    & > div {
      padding-left: 18px;
      padding-right: 18px;
    }
  }

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
  }
`;

const TeamTitle = styled("h2")`
  font-weight: 600;
  font-size: 2rem;
  color: ${Colors.ezBlack};
  margin-bottom: 18px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-bottom: 2.75rem;
    font-size: 3.75rem;
  }
`;

const AboutEzDivisor = styled(EzDivisor)`
  &::after {
    background-color: ${Colors.sharpBlue};
  }
`;

const GrayBG = styled("div")`
  background: ${Colors.gray8};
  margin-top: 45px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
  }
`;

const VVMContainer = styled(RespContainer)`
  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
  }
`;

const VVMWrapper = styled("div")`
  margin-top: 10px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-top: 82px;
  }
`;

const ItemWrapper = styled("div")`
  margin-top: 22px;
  margin-bottom: 36px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: flex;

    margin-bottom: 50px;
  }
`;

const ItemTitleWrapper = styled("div")`
  margin-bottom: 15px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    width: 20%;
  }

  /* >= 1800px; */
  @media all and (min-width: ${Devices.laptopBreakpoint}) {
    width: 17%;
  }
`;

const ItemTitle = styled("h2")`
  margin-bottom: .5rem;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-bottom: .75rem;
  }
`;

const ItemText = styled("p")`
  font-size: 18px;
  line-height: 144%;
  margin-left: 4px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    width: 80%;

    padding-left: 20px;
  }

  /* >= 1800px; */
  @media all and (min-width: ${Devices.laptopBreakpoint}) {
    width: 83%;
  }
`;

const GrayBgWrapper = styled("div")`
  background-color: ${Colors.gray7};
`;
